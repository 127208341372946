import React from "react"
import { Link } from "gatsby"

// comps
import { Section, SectionContent, Seo } from "../components"
import Layout from "../components/Layout"

const About = () => {
  return (
    <Layout>
      <Seo title="Error" />
      <Section>
        <SectionContent customClass="error-page">
          <div className="error-page--wrapper">
            <h3>404</h3>
            <p>Sorry, that page doesn't seem to exist...</p>
            <Link to="/">Return Home</Link>
          </div>
        </SectionContent>
      </Section>
    </Layout>
  )
}
export default About
